import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import { CopyToken } from '@/components/CopyToken';
import { TrblDownloadIcon, TrblFileIcon } from '@/components/Icons';
import { SecondaryButton, TertiaryButton } from '@/components/Shared/Buttons';

import { CodeBlock } from '@/components/CodeBlock';
import { getEnvironment } from '@/utils';
import { useDownloadLatestSdk } from './hooks/useDownloadLatestSdk';
import { useDownloadCredFile } from './hooks/useDownloadCredFile';
import styles from './styles.module.scss';
import { getRefreshToken } from './utils';
import { Stack } from '@mui/material';

interface SdkSsoInfoProps {
  successfulSdkLogin: boolean;
  successfulSdkLoginFallback: boolean;
}

export const SdkSsoInfo: React.FC<SdkSsoInfoProps> = ({ successfulSdkLogin, successfulSdkLoginFallback }) => {
  const [token, setToken] = useState<string | null>(null);
  const { getAccessTokenSilently, logout } = useAuth0();
  const downloadLatestSdk = useDownloadLatestSdk();

  useEffect(() => {
    const getToken = async () => {
      const bearerToken = await getAccessTokenSilently();
      const refreshToken = getRefreshToken();
      let runningEnvironment = getEnvironment();
      runningEnvironment = runningEnvironment === 'local' ? 'dev' : runningEnvironment;

      if (runningEnvironment && refreshToken && bearerToken) {
        const tokenString = `${bearerToken}|${refreshToken}|${runningEnvironment}`;
        setToken(btoa(tokenString));
      }
    };

    if (successfulSdkLogin) {
      getToken().catch((e) => {
        if (e.error === 'login_required' || e.error === 'invalid_grant') {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      });
    }
  }, [successfulSdkLogin, getAccessTokenSilently, logout]);

  return successfulSdkLogin ? (
    <SsoTokenSection downloadLatestSdk={downloadLatestSdk} token={token} />
  ) : successfulSdkLoginFallback ? (
    <SdkTokenSection downloadLatestSdk={downloadLatestSdk} />
  ) : (
    <></>
  );
};

const SsoTokenSection = ({ downloadLatestSdk, token }: { downloadLatestSdk: () => void; token: string | null }) => (
  <div className={styles['sdk-info-page']}>
    <div className={styles['header']}>
      <h1>Treble SDK</h1>
      <div className={styles['header-actions']}>
        <SecondaryButton
          label="Documentation"
          width={'fit-content'}
          icon={<TrblFileIcon />}
          onClick={() => window.open('https://docs.treble.tech/treble-sdk', '_blank', 'noopener,noreferrer')}
        />
        <TertiaryButton
          onClick={downloadLatestSdk}
          icon={<TrblDownloadIcon fill={'#00f5ba'} />}
          label="Download latest version"
        />
      </div>
    </div>
    <div className={styles['info-section']}>
      <h2>SSO credentials</h2>
      <CopyToken token={token || ''} />
    </div>
    <div className={styles['info-section']}>
      <h2>Using the SSO credentials</h2>
      <p>
        There are two ways you can choose from when using the SSO credentials. <br /> You can set the SSO credentials as
        an environment variable or alternatively you can provide the SSO credentials when initializing the SDK.
      </p>
    </div>
    <div className={styles['info-section']}>
      <h4>Option 1: Set the SSO credentials as an environment variable</h4>
      <CodeBlock showLineNumbers language="python">
        {`# Note: On windows the command is "set" instead of "export" \nexport TSDK_SSO_TOKEN=${token}`}
      </CodeBlock>
      <p>Then you can initialize the SDK this way:</p>
      <CodeBlock showLineNumbers language="python">
        {'from treble_tsdk.tsdk import TSDK \ntsdk = TSDK()'}
      </CodeBlock>
    </div>
    <div className={styles['info-section']}>
      <h4>Option 2: Provide the SSO credentials when initializing the SDK</h4>
      <p>
        If you don't want to set the SSO credentials as an environment variable, you can pass it directly when
        initializing the SDK. This allows you to use the credentials in a programmatic manner. Here is an example:
      </p>
      <CodeBlock showLineNumbers language="python">
        {`from treble_tsdk.tsdk import TSDK, TSDKCredentials \ntsdk = TSDK(TSDKCredentials.sso('${token}'))`}
      </CodeBlock>
    </div>
  </div>
);

const SdkTokenSection = ({ downloadLatestSdk }: { downloadLatestSdk: () => void }) => (
  <div className={styles['sdk-info-page']}>
    <div className={styles['header']}>
      <h1>Treble SDK - Getting started</h1>
    </div>
    <div className={styles['info-section']}>
      <p>
        To get started generate the SDK user credentials and download the latest Python package. <br />
        Visit our documentation for the installation guide and code examples, and feel free to contact{' '}
        <a href="mailto:support@treble.tech">support@treble.tech</a> for help.
      </p>
      <Stack flexDirection="row" gap={12} marginTop={1}>
        <Stack flexDirection="column" gap={1}>
          <h2>SDK credentials</h2>
          <TertiaryButton
            onClick={useDownloadCredFile()}
            icon={<TrblDownloadIcon fill={'#00f5ba'} />}
            label="Generate user credentials (.cred)"
            width="fit-content"
          />
        </Stack>
        <Stack flexDirection="column" gap={1}>
          <h2>SDK Python package</h2>
          <TertiaryButton
            onClick={downloadLatestSdk}
            icon={<TrblDownloadIcon fill={'#00f5ba'} />}
            label="Download latest version"
            width="fit-content"
          />
        </Stack>

        <Stack flexDirection="column" gap={1}>
          <h2>Installation guide</h2>
          <SecondaryButton
            onClick={() => window.open('https://docs.treble.tech/treble-sdk', '_blank', 'noopener,noreferrer')}
            icon={<TrblFileIcon width="16" />}
            label="Documentation"
            width="fit-content"
          />
        </Stack>
      </Stack>
    </div>
    <div className={styles['info-section']}>
      <h3>Using the SDK credentials</h3>
      <p>
        There are three options you can choose from when using the SDK credentials. <br /> You can place the SDK
        credentials in a default location, set them as an environment variable or provide them when initializing the
        SDK.
      </p>
      <b>
        <i>
          Note: New credentials get created everytime user credentials are generated (and the old ones become invalid).
        </i>
      </b>
    </div>
    <div className={styles['info-section']}>
      <h4>Option 1: Place the SDK credentials in a default location</h4>
      <p>
        When you create a TSDK object it will search for a <i>.cred</i> file in a few locations, depending on your
        operation system. <br />
        Please put the <i>.cred</i> file in the correct directory:
      </p>
      <CodeBlock showLineNumbers language="python">
        {`Windows: %userprofile%/AppData/Local/treble/tsdk/tsdk.cred and %userprofile%/AppData/Roaming/treble/tsdk/tsdk.cred\nLinux: ~/.config/treble/tsdk.cred\nMacos: ~/.config/treble/tsdk.cred and ~/Library/Application Support/treble/tsdk.cred`}
      </CodeBlock>
      <p>Then you can initialize the SDK this way:</p>
      <CodeBlock showLineNumbers language="python">
        {'from treble_tsdk.tsdk import TSDK \ntsdk = TSDK()'}
      </CodeBlock>
    </div>

    <div className={styles['info-section']}>
      <h4>Option 2: Set the SDK credentials as an environment variable</h4>
      <p>
        If you prefer to manage the SDK credentials through the environment, you can copy the credentials from the{' '}
        <i>.cred</i> file and set it as an environment variable. This is commonly used in production or automated
        environments where hardcoding credentials isn't preferred.
      </p>
      <CodeBlock showLineNumbers language="python">
        {`# Note: On windows the command is "set" instead of "export" \nexport TSDK_CREDENTIALS=[YOUR_CREDENTIALS_HERE]`}
      </CodeBlock>
      <p>Then you can initialize the SDK this way:</p>
      <CodeBlock showLineNumbers language="python">
        {'from treble_tsdk.tsdk import TSDK \ntsdk = TSDK()'}
      </CodeBlock>
    </div>

    <div className={styles['info-section']} style={{ paddingBottom: 32 }}>
      <h4>Option 3: Provide the SDK credentials when initializing the SDK</h4>
      <p>
        Another option is to directly pass the SDK credentials when initializing the SDK. This is ideal for scenarios
        where the credentials is generated dynamically or needs to be provided programmatically. Here's an example of
        how to pass it:
      </p>
      <CodeBlock showLineNumbers language="python">
        {`from treble_tsdk.tsdk import TSDK, TSDKCredentials \ntsdk = TSDK(TSDKCredentials.from_file("/path/to/cred/tsdk.cred"))`}
      </CodeBlock>
    </div>
  </div>
);
